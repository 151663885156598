<template>
  <div class="help-close-ticket px-14 py-8">
    <div class="inline-flex space-x-2 items-center">
      <img class="w-18 h-18" :src="icareLogo" />
      <div>
        <div class="text-5xl font-bold">I Care</div>
        <div class="text-xs font-medium">Best Way for Your Solution</div>
      </div>
    </div>

    <div class="font-bold text-2xl mt-8 mb-1 text-center">Close Ticket</div>
    <div class="mx-auto" style="border-bottom: 3px solid #9b9b9b; width: 223px"></div>

    <div class="grid-cols-1 mt-11">
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            1
          </div>
        </div>
        <div class="text-sm">Masuk ke halaman <span class="font-semibold">"My Ticket"</span></div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-8">
        <div>
          <img :src="CT1" />
        </div>
        <div class="text-sm">
          Pada halaman <span class="font-semibold">my ticket,</span> dibagian filter
          <span class="font-semibold">status</span> pilih status
          <span class="font-semibold">“Done”</span> untuk menampilkan tiket dengan status
          <span class="font-semibold">Done</span> dan kemudian klik disalah satu tiket untuk masuk
          ke detail tiket atau anda dapat mencari tiket dikolom pencarian.
        </div>
      </div>

      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            2
          </div>
        </div>
        <div class="text-sm">
          List tiket status done juga dapat dilihat dihalaman utama pada fitur
          <span class="font-semibold">“Please close your ticket”,</span> anda dapat klik tombol
          <span class="font-semibold">close</span> untuk masuk ke halaman
          <span class="font-semibold">close ticket</span> atau klik pada nomor tiket untuk masuk ke
          halaman detail tiket.
        </div>
      </div>
      <div class="grid grid-cols-1 gap-3 mb-8">
        <div>
          <img :src="CT6" />
        </div>
      </div>

      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            3
          </div>
        </div>
        <div class="text-sm">
          Pada Halaman <span class="font-semibold">detail ticket</span> status done, klik tombol
          <span class="font-semibold">close ticket</span> untuk melanjutkan proses
          <span class="font-semibold">close ticket</span>.
        </div>
      </div>
      <div class="grid grid-cols-1 gap-3 mb-8">
        <div>
          <img :src="CT4" />
        </div>
      </div>

      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            4
          </div>
        </div>
        <div class="text-sm">Akan tampil pop up seperti gambar dibawah.</div>
      </div>
      <div class="grid grid-cols-1 gap-3 mb-8">
        <div>
          <img :src="CT7" />
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-8">
        <div>
          <img :src="User2" />
        </div>
        <div class="text-sm">
          pada bagian ini tentukan <span class="font-semibold">jumlah bintang</span> yang akan
          diberikan terhadap <span class="font-semibold">kinerja</span> dari
          <span class="font-semibold">tim support</span> dalam menangani tiket dan jangan lupa untuk
          memberikan komentar, komentar dapat dipilih dari draft yang disediakan aplikasi atau anda
          juga dapat menulis sesuai kebutuhan, setelah data telah lengkap terisi, klik tombol
          <span class="font-semibold">close ticket</span> untuk melanjutkan proses.
        </div>
      </div>

      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            5
          </div>
        </div>
        <div class="text-sm">Proses close ticket telah selesai.</div>
      </div>
      <div class="grid grid-cols-1 gap-3 mb-8">
        <div>
          <img :src="CT8" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icareLogo from '@/assets/images/i_care_logo_large.png'
import CT1 from '@/assets/images/help-ct1.png'
import CT4 from '@/assets/images/help-ct4.png'
import CT6 from '@/assets/images/help-ct6.png'
import CT7 from '@/assets/images/help-ct7.png'
import CT8 from '@/assets/images/help-ct8.png'

export default {
  name: 'help-close-ticket',
  data() {
    return {
      icareLogo,
      CT1,
      CT4,
      CT6,
      CT7,
      CT8
    }
  }
}
</script>
