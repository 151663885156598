<template>
  <div class="help-feature px-14 py-8">
    <div class="inline-flex space-x-2 items-center">
      <img class="w-18 h-18" :src="icareLogo" />
      <div>
        <div class="text-5xl font-bold">I Care</div>
        <div class="text-xs font-medium">Best Way for Your Solution</div>
      </div>
    </div>
    <div class="font-bold text-2xl mt-7 mb-5">Fitur Utama</div>

    <img :src="FeatureMenu" />
    <div class="grid-cols-1 mt-11">
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            1
          </div>
        </div>
        <div class="text-sm">
          <span class="font-semibold">Open Ticket,</span> Digunakan customer/user untuk menghubungi
          PIC Support kami.
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            2
          </div>
        </div>
        <div class="text-sm">
          <span class="font-semibold">My Ticket,</span> Daftar ticket yang telah dibuat oleh user
          customer maupun oleh supervisor support, lengkap dengan status ticket yang sedang
          dikerjakan oleh staf support kami.
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            3
          </div>
        </div>
        <div class="text-sm">
          <span class="font-semibold">Instrument,</span> Daftar instrument yang telah di install
          pada customer
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            4
          </div>
        </div>
        <div class="text-sm">
          <span class="font-semibold">Planning,</span> Menampilkan jadwal plan yang dijadwalkan dari
          tim support customer.
        </div>
      </div>
    </div>

    <div class="font-bold text-2xl mt-7 mb-5">Header</div>
    <img :src="FeatureNav" />
    <div class="grid-cols-1 mt-11">
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            1
          </div>
        </div>
        <div class="text-sm">
          <span class="font-semibold">Home,</span> Digunakan untuk kembali ke halaman utama.
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            2
          </div>
        </div>
        <div class="text-sm">
          <span class="font-semibold">Contact,</span> Menampilkan daftar contact person tim support
          yang dapat dihubungi.
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            3
          </div>
        </div>
        <div class="text-sm">
          <span class="font-semibold">History,</span> Menampilkan daftar history ticket yang
          berstatus closed pada customer.
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            4
          </div>
        </div>
        <div class="text-sm">
          <span class="font-semibold">Help Me,</span> Menampilkan tata cara penggunaan dari aplikasi
          I Care.
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            5
          </div>
        </div>
        <div class="text-sm">
          <span class="font-semibold">Notifications,</span> Memberi informasi terakhir terkait
          perubahan yang terjadi pada ticket.
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            6
          </div>
        </div>
        <div class="text-sm">
          <span class="font-semibold">Broadcast Message,</span> Menampilkan pesan broadcast ketika
          ada informasi terkait regulasi baru maupun informasi update dari sistem.
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            7
          </div>
        </div>
        <div class="text-sm">
          <span class="font-semibold">User,</span> Menampilkan username dan dropdown menu setting
          dan logout.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icareLogo from '@/assets/images/i_care_logo_large.png'

import IconTicket from '@/assets/icons/icon-openTicket.svg'
import IconInstrument from '@/assets/icons/icon-myTicket.svg'
import IconMyTicket from '@/assets/icons/icon-instruments.svg'
import IconListPlan from '@/assets/icons/icon-plannings.svg'

import FeatureMenu from '@/assets/images/help-feature-menu.png'
import FeatureNav from '@/assets/images/help-feature-nav.png'

export default {
  name: 'help-feature',
  data() {
    return {
      icareLogo,
      FeatureMenu,
      FeatureNav,

      menus: [
        {
          id: 'open',
          label: 'Open Ticket',
          sublabel: 'Create your ticket here',
          icon: IconTicket,
          to: 'open.technical'
        },
        {
          id: 'my',
          label: 'My Ticket',
          sublabel: 'Manage your ticket here',
          icon: IconMyTicket,
          to: 'ticket.index'
        },
        {
          id: 'instrument',
          label: 'Instruments',
          sublabel: 'List of instruments used',
          icon: IconInstrument,
          to: 'instrument.index'
        },
        {
          id: 'planning',
          label: 'Plannings',
          sublabel: 'Schedule plan list',
          icon: IconListPlan,
          to: 'planning.index'
        }
      ]
    }
  }
}
</script>
