<template>
  <div class="help-cancel-ticket px-14 py-8">
    <div class="inline-flex space-x-2 items-center">
      <img class="w-18 h-18" :src="icareLogo" />
      <div>
        <div class="text-5xl font-bold">I Care</div>
        <div class="text-xs font-medium">Best Way for Your Solution</div>
      </div>
    </div>

    <div class="font-bold text-2xl mt-8 mb-1 text-center">Cancel Ticket</div>
    <div class="mx-auto" style="border-bottom: 3px solid #9b9b9b; width: 223px"></div>

    <div class="grid-cols-1 mt-11">
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            1
          </div>
        </div>
        <div class="text-sm">Masuk ke halaman <span class="font-semibold">"My Ticket"</span></div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-8">
        <div>
          <img :src="CT1" />
        </div>
        <div class="text-sm">
          Pada halaman <span class="font-semibold">my ticket,</span> dibagian filter
          <span class="font-semibold">status</span> pilih status
          <span class="font-semibold">“New”</span> untuk menampilkan tiket dengan status
          <span class="font-semibold">New</span> dan kemudian klik disalah satu tiket untuk masuk ke
          detail tiket atau anda dapat mencari tiket dikolom pencarian.
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            2
          </div>
        </div>
        <div class="text-sm">
          Pada Halaman <span class="font-semibold">detail ticket</span> status new, klik tombol
          <span class="font-semibold">cancel ticket</span> untuk melanjutkan proses
          <span class="font-semibold">cancel ticket</span>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-3 mb-8">
        <div>
          <img :src="CT2" />
        </div>
      </div>

      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            3
          </div>
        </div>
        <div class="text-sm">
          Akan tampil alert seperti gambar dibawah ini, klik
          <span class="font-semibold">ok</span> untuk melanjutkan proses dan klik cancel untuk
          membatalkan.
        </div>
      </div>
      <div class="grid grid-cols-1 gap-3">
        <div>
          <img :src="CT3" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icareLogo from '@/assets/images/i_care_logo_large.png'
import CT1 from '@/assets/images/help-ct1.png'
import CT2 from '@/assets/images/help-ct2.png'
import CT3 from '@/assets/images/help-ct3.png'

export default {
  name: 'help-cancel-ticket',
  data() {
    return {
      icareLogo,
      CT1,
      CT2,
      CT3
    }
  }
}
</script>
