<template>
  <div class="help-about px-14 py-8">
    <div class="w-full">
      <youtube video-id="YktyvARpjhA" ref="youtube" resize></youtube>
    </div>

    <div class="inline-flex space-x-2 items-center mt-14">
      <img class="w-18 h-18" :src="icareLogo" />
      <div>
        <div class="text-5xl font-bold">I Care</div>
        <div class="text-xs font-medium">Best Way for Your Solution</div>
      </div>
    </div>

    <div class="mt-8">
      <p class="text-sm">
        <span class="font-semibold">I CARE</span>, Adalah sebuah software/aplikasi yang dapat
        menghubungkan antara Customer dan PIC I Care, Sehingga bila terjadi kerusakan pada alat,
        hasil alat, IT, Hanter, maka customer dapat menghubungi PIC Support kami melaului fitur Open
        Ticket, Ticket akan automatis dikirimkan ke PIC Support, SPV Support, dan Manajemen PT. Saba
        Indomedika, Sehingga ticket yang di open oleh customer akan termonitor.
        <br />
        <br />
        Aplikasi <span class="font-semibold">I CARE</span> ini dapat diakses menggunakan browser
        maupun dapat diunduh pada playstore bagi pengguna android atau dapat langsung klik pada icon
        playstore dibawah.
        <br />
        <br />
        Klik icon untuk mengunduh
        <br />
        <span class="font-semibold">I CARE</span>
      </p>
      <img :src="gpIcon" class="my-6" />
    </div>
  </div>
</template>

<script>
import icareLogo from '@/assets/images/i_care_logo_large.png'
import gpIcon from '@/assets/images/google_play.png'

export default {
  name: 'help-about',
  data() {
    return {
      icareLogo,
      gpIcon
    }
  }
}
</script>
