<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10" ref="scc">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          @click="$router.go(-1)"
          style="max-width: 200px"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">Help Me</span>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-3 sm:flex sm:space-x-6 relative z-10">
        <div class="flex-none order-last sm:order-first">
          <div class="grid grid-cols-1 gap-3">
            <div
              class="help-menu-card relative cursor-pointer sm:hmc-sm"
              :class="m.id == menu ? 'bg-hblue4' : 'bg-white'"
              v-for="m in menus"
              :key="m.id"
              @click="toMenu(m.id)"
            >
              <div class="flex space-x-4">
                <IconAbout class="h-7.31 w-8.33" v-if="m.id == 'about'" />
                <IconRegister class="h-7.31 w-8.33" v-else-if="m.id == 'registration'" />
                <IconFeature class="h-7.31 w-8.33" v-else-if="m.id == 'feature'" />
                <IconOpenTicket class="h-7.31 w-8.33 mt-0.5" v-else-if="m.id == 'openticket'" />
                <IconCloseTicket class="h-7.31 w-8.33" v-else-if="m.id == 'closeticket'" />
                <IconCancelTicket class="h-7.31 w-8.33" v-else-if="m.id == 'cancelticket'" />
                <IconUpdateUser class="h-7.31 w-8.33" v-else-if="m.id == 'updateuser'" />
                <IconQuick class="h-7.31 w-8.33" v-else />
                <div class="text-sm mt-1.2" :class="menu == m.id ? 'text-white' : 'text-black'">
                  {{ m.label }}
                </div>
                <IconArrowNav
                  class="absolute right-5 top-6 stroke-current"
                  :class="m.id == menu ? 'text-white' : 'text-hgray4'"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="help-slot-card">
          <my-scroll :ops="configSlot" ref="hc">
            <section-about v-if="menu == 'about'" />
            <section-register v-else-if="menu == 'registration'" />
            <section-feature v-else-if="menu == 'feature'" />
            <section-open-ticket v-else-if="menu == 'openticket'" />
            <section-close-ticket v-else-if="menu == 'closeticket'" />
            <section-cancel-ticket v-else-if="menu == 'cancelticket'" />
            <section-user v-else-if="menu == 'updateuser'" />
            <section-quick v-else-if="menu == 'quick'" />
          </my-scroll>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconAbout from '@/assets/icons/help-about.svg?inline'
import IconRegister from '@/assets/icons/help-register.svg?inline'
import IconFeature from '@/assets/icons/help-feature.svg?inline'
import IconOpenTicket from '@/assets/icons/help-openticket.svg?inline'
import IconCloseTicket from '@/assets/icons/help-closeticket.svg?inline'
import IconCancelTicket from '@/assets/icons/help-cancelticket.svg?inline'
import IconUpdateUser from '@/assets/icons/help-updateuser.svg?inline'
import IconQuick from '@/assets/icons/help-quick.svg?inline'

import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import IconArrowNav from '@/assets/icons/icon-arrow-nav.svg?inline'

import SectionAbout from '@/components/helpme/About'
import SectionRegister from '@/components/helpme/Registration'
import SectionFeature from '@/components/helpme/Feature'
import SectionUser from '@/components/helpme/UpdateUser'
import SectionQuick from '@/components/helpme/QuickReference'
import SectionOpenTicket from '@/components/helpme/OpenTicket'
import SectionCloseTicket from '@/components/helpme/CloseTicket'
import SectionCancelTicket from '@/components/helpme/CancelTicket'

export default {
  name: 'help-index',
  components: {
    IconAbout,
    IconRegister,
    IconFeature,
    IconOpenTicket,
    IconCloseTicket,
    IconCancelTicket,
    IconUpdateUser,
    IconQuick,
    IconArrow,
    IconArrowNav,

    SectionAbout,
    SectionRegister,
    SectionFeature,
    SectionUser,
    SectionCloseTicket,
    SectionCancelTicket,
    SectionOpenTicket,
    SectionQuick
  },
  data() {
    return {
      menu: 'about',
      menus: [
        {
          id: 'about',
          label: 'Apa itu I Care?'
        },
        {
          id: 'registration',
          label: 'Registrasi'
        },
        {
          id: 'feature',
          label: 'Fitur'
        },
        {
          id: 'openticket',
          label: 'Open Ticket'
        },
        {
          id: 'closeticket',
          label: 'Close Ticket'
        },
        {
          id: 'cancelticket',
          label: 'Cancel Ticket'
        },
        {
          id: 'updateuser',
          label: 'Update User'
        },
        {
          id: 'quick',
          label: 'Quick Reference'
        }
      ],
      configSlot: {
        vuescroll: {
          mode: 'native',
          detectResize: true,
          locking: true
        },
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
          verticalNativeBarPos: 'right'
        },
        bar: {
          background: '#176FA9',
          opacity: 1,
          size: '5px',
          minSize: 0.2
        }
      }
    }
  },
  methods: {
    toMenu(val) {
      this.menu = val
      this.$refs.scc.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }
}
</script>
