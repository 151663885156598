<template>
  <div class="help-cancel-ticket px-14 py-8">
    <div class="inline-flex space-x-2 items-center">
      <img class="w-18 h-18" :src="icareLogo" />
      <div>
        <div class="text-5xl font-bold">I Care</div>
        <div class="text-xs font-medium">Best Way for Your Solution</div>
      </div>
    </div>

    <div class="font-bold text-2xl mt-8 mb-5 text-center">Open Ticket</div>
    <div class="mx-auto" style="border-bottom: 3px solid #9b9b9b; width: 223px"></div>

    <div class="mt-11">
      <div class="text-xs font-semibold">Play this video:</div>
      <div class="flex space-x-3 mt-4">
        <button class="bg-hblue4 rounded py-2 w-44" @click="MODAL_VIDEO('TPDuTdOMaz0')">
          <div class="flex space-x-3 justify-center">
            <Play class="mt-1" />
            <span class="text-white text-sm align-middle">I Care Android</span>
          </div>
        </button>
        <button class="bg-hblue4 rounded py-2 w-44" @click="MODAL_VIDEO('SpDh79fMFOM')">
          <div class="flex space-x-3 justify-center">
            <Play class="mt-1" />
            <span class="text-white text-sm align-middle">I Care Web</span>
          </div>
        </button>
      </div>
    </div>

    <div class="grid-cols-1 mt-11">
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            1
          </div>
        </div>
        <div class="text-sm">Klik icon <span class="font-semibold">“Open Ticket”</span></div>
      </div>
      <div class="grid grid-cols-1 gap-3 mb-8">
        <div>
          <img :src="OT1" />
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            2
          </div>
        </div>
        <div class="text-sm">
          Pilih salah satu
          <span class="font-semibold">“Ticket Type”</span>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-3 mb-8">
        <div>
          <img :src="OT2" />
        </div>
      </div>

      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            3
          </div>
        </div>
        <div class="text-sm">Isikan form ticket.</div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-5">
        <div>
          <div class="text-sm">Form <span class="font-semibold">Technical Complaint </span></div>
          <img :src="OT3" />
        </div>
        <div>
          <ul class="list-disc mt-1 text-hblue4 ml-3">
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Pertama, pilih <span class="font-semibold">Division </span></span
              >
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Pilih <span class="font-semibold">Instrument</span> yang terkait dengan ticket.
              </span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black"> Isikan <span class="font-semibold">Note</span> </span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Lampirkan <span class="font-semibold">nama orang </span> yang bertanggung jawab
                terhadap ticket.
              </span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Isikan <span class="font-semibold">nomor telephone</span> yang dapat dihubungi
              </span>
            </li>
            <li class="text-sm mb-0.5"><span class="text-black"> Pilih priority ticket.</span></li>
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Klik tombol <span class="font-semibold">“Create ticket”</span> untuk ketika semua
                kolom sudah terisi dengan benar.
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-5">
        <div>
          <div class="text-sm">Form <span class="font-semibold">Request </span></div>
          <img :src="OT4" />
        </div>
        <div>
          <ul class="list-disc mt-1 text-hblue4 ml-3">
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Pertama, pilih <span class="font-semibold">Division </span></span
              >
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Pilih <span class="font-semibold">Instrument</span> yang terkait dengan ticket.
              </span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black">Isikan <span class="font-semibold">Note</span> </span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Isikan <span class="font-semibold">Contact person</span> nama orang yang bertanggung
                jawab terhadap ticket.
              </span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Isikan <span class="font-semibold">nomor telephone</span> yang dapat dihubungi
              </span>
            </li>
            <li class="text-sm mb-0.5"><span class="text-black"> Pilih priority ticket.</span></li>
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Klik tombol <span class="font-semibold">“Create ticket”</span> untuk ketika semua
                kolom sudah terisi dengan benar.
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="flex space-x-3 mb-3">
        <div>
          <div class="text-sm">
            Form <span class="font-semibold">Non Technical Complaint </span>
          </div>
          <img :src="OT5" />
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-8">
        <div>
          <ul class="list-disc mt-1 text-hblue4 ml-3">
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Pilih <span class="font-semibold">“Department”</span>
                ticket yang akan dibuat.
              </span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black"> Isikan <span class="font-semibold">“note”</span></span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black"
                >Isikan <span class="font-semibold">“Contact person”</span>
                nama orang yang bertanggung jawab terhadap ticket.
              </span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Isikan <span class="font-semibold">nomor telephone</span> yang datap dihubungi.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <ul class="list-disc mt-1 text-hblue4 ml-3">
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Klik choose file untuk menggunggah file gambar, lampiran ini bersifat opsional.
              </span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black"> Pilih priority ticket.</span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Klik tombol <span class="font-semibold">“Create ticket”</span>
                untuk ketika semua kolom sudah terisi dengan benar.
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="flex space-x-3 mb-3">
        <div>
          <div class="text-sm">Form <span class="font-semibold">Non Technical Request </span></div>
          <img :src="OT8" />
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-8">
        <div>
          <ul class="list-disc mt-1 text-hblue4 ml-3">
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Pilih <span class="font-semibold">“Department”</span>
                ticket yang akan dibuat.
              </span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black"> Isikan <span class="font-semibold">“note”</span></span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black"
                >Isikan <span class="font-semibold">“Contact person”</span>
                nama orang yang bertanggung jawab terhadap ticket.
              </span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Isikan <span class="font-semibold">nomor telephone</span> yang datap dihubungi.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <ul class="list-disc mt-1 text-hblue4 ml-3">
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Klik choose file untuk menggunggah file gambar, lampiran ini bersifat opsional.
              </span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black"> Pilih priority ticket.</span>
            </li>
            <li class="text-sm mb-0.5">
              <span class="text-black">
                Klik tombol <span class="font-semibold">“Create ticket”</span>
                untuk ketika semua kolom sudah terisi dengan benar.
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            4
          </div>
        </div>
        <div class="text-sm">Confirmation Page</div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-8">
        <div>
          <img :src="OT6" />
        </div>
        <div class="text-sm">
          Sebelum tiket berhasil dibuat, akan tampil halaman konfirmasi untuk memastikan form tiket
          terisi dengan data yang benar, anda dapat mengubah data tiket dengan klik tombol
          <span class="font-semibold">“edit”</span>, jika data sudah benar klik tombol
          <span class="font-semibold">“confirm”</span> untuk melanjutkan proses open ticket.
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            5
          </div>
        </div>
        <div class="text-sm">Ticket Created</div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-8">
        <div>
          <img :src="OT7" />
        </div>
        <div class="text-sm">
          Bagian menampilkan pop-up tiket berhasil dibuat, klik
          <span class="font-semibold">“detail ticket”</span> untuk masuk ke halaman detail dari
          tiket yang baru saja dibuat dan klik
          <span class="font-semibold">“back to home”</span> untuk kembali halaman utama.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icareLogo from '@/assets/images/i_care_logo_large.png'
import OT1 from '@/assets/images/help-ot1.png'
import OT2 from '@/assets/images/help-ot2.png'
import OT3 from '@/assets/images/help-ot3.png'
import OT4 from '@/assets/images/help-ot4.png'
import OT5 from '@/assets/images/help-ot5.png'
import OT6 from '@/assets/images/help-ot6.png'
import OT7 from '@/assets/images/help-ot7.png'
import OT8 from '@/assets/images/help-ot8.png'
import Play from '@/assets/icons/play.svg?inline'

import { mapActions } from 'vuex'

export default {
  name: 'help-open-ticket',
  components: {
    Play
  },
  data() {
    return {
      icareLogo,
      OT1,
      OT2,
      OT3,
      OT4,
      OT5,
      OT6,
      OT7,
      OT8
    }
  },
  methods: {
    ...mapActions('modal', ['MODAL_VIDEO'])
  }
}
</script>
