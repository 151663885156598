<template>
  <div class="help-qucik px-14 py-8">
    <div class="inline-flex space-x-2 items-center">
      <img class="w-11.3 h-11.3" :src="icareLogo" />
      <div>
        <div class="text-3xl font-bold">I Care</div>
        <div class="text-xs font-medium">Best Way for Your Solution</div>
      </div>
    </div>
    <!-- <a
      :href="icarePdf"
      target="_blank"
      download
      class="btn btn-ghost sm:block hidden absolute right-8 top-9 capitalize pt-1"
    >
      <div class="flex mt-0.5">
        <SvgDownload class="mt-1.3 mr-3" />
        <span class="text-2xl font-semibold">Download</span>
      </div>
    </a> -->

    <div class="font-bold text-2xl mt-10 mb-1 text-center">Quick Reference</div>
    <div class="mx-auto" style="border-bottom: 3px solid #9b9b9b; width: 223px"></div>

    <div class="grid grid-cols-1 mt-8">
      <div>
        <iframe :src="icarePdf" class="w-full" style="height: 640px"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import icareLogo from '@/assets/images/i_care_logo_large.png'
// import SvgDownload from '@/assets/icons/icon-download.svg?inline'
import icarePdf from '@/assets/icare.pdf'

export default {
  name: 'help-quick',
  // components: {
  //   SvgDownload
  // },
  data() {
    return {
      icareLogo,
      icarePdf
    }
  }
}
</script>
