<template>
  <div class="help-update-user px-14 py-8">
    <div class="inline-flex space-x-2 items-center">
      <img class="w-18 h-18" :src="icareLogo" />
      <div>
        <div class="text-5xl font-bold">I Care</div>
        <div class="text-xs font-medium">Best Way for Your Solution</div>
      </div>
    </div>

    <div class="font-bold text-2xl mt-8 mb-1 text-center">Edit Profile</div>
    <div class="mx-auto" style="border-bottom: 3px solid #9b9b9b; width: 223px"></div>

    <div class="grid-cols-1 mt-11">
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            1
          </div>
        </div>
        <div class="text-sm">Masuk ke halaman <span class="font-semibold">"Profile"</span></div>
      </div>
      <div>
        <img :src="User1" />
      </div>
      <div class="flex space-x-3 mb-7 -mt-8">
        <div class="flex-none">
          <div
            class="h-5 w-5 text-transparent text-sm font-semibold rounded-full text-center"
          ></div>
        </div>
        <div class="text-sm">
          Pada halaman <span class="font-semibold">Profile,</span> bisa diakses pada menu header
          seperti gambar diatas, kemudian pilih <span class="font-semibold">Profile</span>
        </div>
      </div>
      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            2
          </div>
        </div>
        <div class="text-sm">Halaman <span class="font-semibold">User Profile</span></div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-8">
        <div>
          <img :src="User2" />
        </div>
        <div class="text-sm">
          Pada halaman <span class="font-semibold">Uer Profile</span> seperti gambar dibawah ini
          menampilkan informasi customer dan daftar tim support yang bertugas pada customer
          tersebut. Untuk edit user, anda dapat klik tombol
          <span class="font-semibold">Edit</span> pada pada bagian
          <span class="font-semibold">User</span>
        </div>
      </div>

      <div class="flex space-x-3 mb-3">
        <div class="flex-none">
          <div class="bg-hblue4 h-5 w-5 text-white text-sm font-semibold rounded-full text-center">
            3
          </div>
        </div>
        <div class="text-sm">Form <span class="font-semibold">Edit Profile</span></div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <div>
          <img :src="User3" />
        </div>
        <div class="text-sm">
          Isikan form <span class="font-semibold">Edit Profile</span> sesuai dengan kebutuhan,
          kemudian klik tomol <span class="font-semibold">Save</span> untuk melanjutkan.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icareLogo from '@/assets/images/i_care_logo_large.png'
import User1 from '@/assets/images/help-user1.png'
import User2 from '@/assets/images/help-user2.png'
import User3 from '@/assets/images/help-user3.png'

export default {
  name: 'help-update-user',
  data() {
    return {
      icareLogo,
      User1,
      User2,
      User3
    }
  }
}
</script>
