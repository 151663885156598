<template>
  <div class="help-register px-14 py-8">
    <div class="inline-flex space-x-2 items-center">
      <img class="w-18 h-18" :src="icareLogo" />
      <div>
        <div class="text-5xl font-bold">I Care</div>
        <div class="text-xs font-medium">Best Way for Your Solution</div>
      </div>
    </div>
    <div class="font-bold text-2xl mt-8 mb-5">Registrasi</div>
    <p class="text-sm">
      Untuk registrasi, user akan diberikan form registrasi yang akan diberikan langsung oleh
      staff/Team Support kami, setelah form registrasi sudah ditanda tangani oleh pihak rumah
      sakit/laborotrium, maka user akan mendapat email,whatsapp balasan dari admin Support kami.
      <br />
      <br />
      <span class="font-semibold">Akun User</span> diberikan kepada :
    </p>
    <ul class="list-disc mt-1 text-hblue4 ml-3.5">
      <li class="text-sm mb-0.5">
        <span class="text-black"> Pegawai tetap, pada rumah sakit/Laboratorium </span>
      </li>
      <li class="text-sm mb-0.5">
        <span class="text-black"
          >Akun user diberikan kepada staf/dokter/penanggung jawab data yang dibutuhkan
        </span>
      </li>
      <li class="text-sm mb-0.5">
        <span class="text-black">Nama rumah sakit/Laboratorium/Universitas</span>
      </li>
      <li class="text-sm mb-0.5">
        <span class="text-black">Alamat & nomor telpon rumah sakit/Laboratorium/Universitas</span>
      </li>
      <li class="text-sm mb-0.5"><span class="text-black">Nama pengguna</span></li>
      <li class="text-sm mb-0.5"><span class="text-black">Nomor HP, (WhatsAPp)</span></li>
      <li class="text-sm mb-0.5"><span class="text-black">Email yang masih aktif</span></li>
    </ul>
  </div>
</template>

<script>
import icareLogo from '@/assets/images/i_care_logo_large.png'

export default {
  name: 'help-registration',
  data() {
    return {
      icareLogo
    }
  }
}
</script>
